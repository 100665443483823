<template>
  <div>
    <p class="uk-text-center">
      Staff: SuzuBucket(后端/数据处理) / Mundanity_fc(前端) / Super_Water_God(数据可视化/俄语支持) / nek0ri(德语支持)
    </p>
    <p class="uk-text-center">
      报错、翻译、数据提交:
      <a
        target="_blank"
        href="mailto:root@k-0n.org"
      >
        root@k-0n.org
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>