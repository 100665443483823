<template>
  <div class="uk-overflow-auto">
    <button
      v-if="currentGameType === 'bfv'"
      class="uk-button uk-button-primary"
      @click="changeType('bf1')"
    >
      {{ $t('messages.to_bf1') }}
    </button>
    <button
      v-if="currentGameType === 'bf1'"
      class="uk-button uk-button-primary"
      @click="changeType('bfv')"
    >
      {{ $t('messages.to_bfv') }}
    </button>

    <table
      id="record"
      class="uk-table uk-table-divider uk-table-hover uk-table-justify uk-animation-fade"
    >
      <thead class="uk-background-default">
        <tr>
          <th>
            {{ $t('messages.table_name') }}
          </th>
          <th
            style="cursor:pointer"
            @click="sort('count')"
          >
            {{ $t('messages.table_number') }}
            <span
              v-if="currentSort === 'count' && currentSortDir === 'desc'"
              uk-icon="icon: arrow-down"
            />
            <span
              v-if="currentSort === 'count' && currentSortDir === 'asc'"
              uk-icon="icon: arrow-up"
            />
          </th>
          <th
            style="cursor:pointer"
            @click="sort('kd')"
          >
            {{ $t('messages.table_kd') }}
            <span
              v-if="currentSort === 'kd' && currentSortDir === 'desc'"
              uk-icon="icon: arrow-down"
            />
            <span
              v-if="currentSort === 'kd' && currentSortDir === 'asc'"
              uk-icon="icon: arrow-up"
            />
          </th>
          <th
            style="cursor:pointer"
            @click="sort('kpm')"
          >
            {{ $t('messages.table_kpm') }}
            <span
              v-if="currentSort === 'kpm' && currentSortDir === 'desc'"
              uk-icon="icon: arrow-down"
            />
            <span
              v-if="currentSort === 'kpm' && currentSortDir === 'asc'"
              uk-icon="icon: arrow-up"
            />
          </th>
          <th
            style="cursor:pointer"
            @click="sort('avg_rank')"
          >
            {{ $t('messages.table_average_rank') }}
            <span
              v-if="currentSort === 'avg_rank' && currentSortDir === 'desc'"
              uk-icon="icon: arrow-down"
            />
            <span
              v-if="currentSort === 'avg_rank' && currentSortDir === 'asc'"
              uk-icon="icon: arrow-up"
            />
          </th>
          <th
            style="cursor:pointer"
            @click="sort('avg_time')"
          >
            {{ $t('messages.table_average_time') }}
            <span
              v-if="currentSort === 'avg_time' && currentSortDir === 'desc'"
              uk-icon="icon: arrow-down"
            />
            <span
              v-if="currentSort === 'avg_time' && currentSortDir === 'asc'"
              uk-icon="icon: arrow-up"
            />
          </th>
          <th
            style="cursor:pointer"
            @click="sort('total_time')"
          >
            {{ $t('messages.table_total_time') }}
            <span
              v-if="currentSort === 'total_time' && currentSortDir === 'desc'"
              uk-icon="icon: arrow-down"
            />
            <span
              v-if="currentSort === 'total_time' && currentSortDir === 'asc'"
              uk-icon="icon: arrow-up"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="record in sorted_records"
          :key="record"
        >
          <td
            v-if="$i18n.locale === 'zh' || $i18n.locale === 'ja'"
            :title="record.random"
          >
            <a :href="record.website">{{ record.name_cn }}</a>
          </td>
          <td
            v-if="$i18n.locale === 'en' || $i18n.locale === 'de' || $i18n.locale === 'ru'"
            :title="record.random"
          >
            <a :href="record.website">{{ record.name_en }}</a>
          </td>
          <td :title="$t('messages.table_number')">
            {{ record.count }}
          </td>
          <td :title="$t('messages.table_kd')">
            {{ record.kd }}
          </td>
          <td :title="$t('messages.table_kpm')">
            {{ record.kpm }}
          </td>
          <td :title="$t('messages.table_average_rank')">
            {{ record.avg_rank }}
          </td>
          <td :title="$t('messages.table_average_time')">
            {{ record.avg_time }}
          </td>
          <td :title="$t('messages.table_total_time')">
            {{ record.total_time }}
          </td>
        </tr>
        <tr title="轻音永不毕业！">
          <!-- 插入最后一个 -->
          <td>
            <a
              target="_blank"
              href="https://zh.moegirl.org.cn/%E6%94%BE%E5%AD%A6%E5%90%8ETEA_TIME"
            >放課後ティータイム</a>
          </td>
          <td>5</td>
          <td>inf.</td>
          <td>inf.</td>
          <td>150</td>
          <td>12年</td>
          <td>60年</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: "RecordTable",
  data() {
    return {
      records: [],
      currentSort: 'count',
      currentSortDir: 'desc',
      currentGameType: 'bf1',
      bf1: 'https://api.juan.k-0n.org/getData?gametype=bf1',
      bfv: 'https://api.juan.k-0n.org/getData?gametype=bfv'
    }
  },
  computed: {
    sorted_records: function () {
      return sortByKey(this.records, this.currentSort, this.currentSortDir)
    }
  },
  created() {
    this.getRecords(this.bf1)
  },
  methods: {
    sort: function (sort_type) {
      if (sort_type === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = sort_type;
    },
    getRecords(gameType) {
      fetch(gameType)
          .then(res => {
            return res.json()
          })
          .then(data => {
            this.records = data.records;
          })
    },
    changeType(type) {
      if (type === 'bf1') {
        this.currentGameType = 'bf1';
        this.records = [];
        this.getRecords(this.bf1);
      }
      if (type === 'bfv') {
        this.currentGameType = 'bfv';
        this.records = [];
        this.getRecords(this.bfv);
      }
    }
  },
}

function sortByKey(array, key, sort_type) {
  return array.sort(function (a, b) {
    let x = a[key];
    let y = b[key];
    if (sort_type === "desc") return ((x < y) ? 1 : (x > y) ? -1 : 0);
    if (sort_type === "asc") return ((x < y) ? -1 : (x > y) ? 1 : 0);
  })
}
</script>

<style scoped>

</style>