import { createI18n } from "vue-i18n";
import messages from './index'

const language = (
    (navigator.language ? navigator.language : navigator.userLanguage) || "zh"
).toLowerCase();

const i18n = createI18n({
    locale: language.split("-")[0] || "zh",
    globalInjection:true,
    messages
})

export default i18n