export default {
    messages: {
        header_title: "Battlefield - University/College Ranking",
        header_detail: "Welcome! Data will be updated every 20 minutes. Table is sorted by record number by default. You can also click on the other table header to change the sort rule.",
        submit_1_title: "Submit through email",
        submit_1_p1: "You can submit your record through your university/college email.",
        submit_1_p2: "If you have any email with address ended with ＠***.edu.***, you can send your [Origin ID, Records, University/College Name] to root＠k-0n.org, our administrator will add your record to this page.",
        submit_1_p3: "If convenient, you can also provide a valid student certificate.",
        submit_2_title: "Submit through a questionnaire",
        submit_2_p1: "You can also submit your record by filling this questionnaire. [!ATTENTION! Questionnaire is Chinese content]",
        submit_2_p2: "Only necessary information needed. REMEMBER to HIDE your personal information. (Photo, Name, ID etc.)",
        submit_2_button: "Click here to submit!",
        warning: "WARNING!",
        hideTip: "Hide your personal information!",
        confirm: "I KNOW",
        to_bf1: "Show BF1 Records",
        to_bfv: "Show BFV Records",
        table_name: "Name",
        table_number: "Record Number",
        table_kd: "KD.",
        table_kpm: "KPM.",
        table_average_rank: "Average Player Rank",
        table_average_time: "Average Time Spent",
        table_total_time: "Total Time Spent"
    }
}