<template>
  <div class="EchartsMap">
    <div
      id="map"
      style="width: 100%;
      height:800px;
      margin: 0 auto;"
    />
  </div>
</template>
<script>
import 'echarts/extension/bmap/bmap'
const echarts = require("echarts")

export default {
  name: 'EchartsMap',
  data() {
    return {
    }
  },
  mounted() {
    this.myEcharts();
  },
  methods: {
    myEcharts() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById('map'));
      let option = {
        title: {
          text: '战地大学生战力排行榜 - 百度地图',
          subtext: 'data from Collection',
          sublink: 'https://status.k-0n.org/',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        bmap: {
          center: [116.397128, 39.916527],
          zoom: 5,
          roam: true,
          mapStyle: {
            styleJson: [{
              'featureType': 'water',
              'elementType': 'all',
              'stylers': {
                'color': '#d1d1d1'
              }
            }, {
              'featureType': 'land',
              'elementType': 'all',
              'stylers': {
                'color': '#f3f3f3'
              }
            }, {
              'featureType': 'railway',
              'elementType': 'all',
              'stylers': {
                'visibility': 'off'
              }
            }, {
              'featureType': 'highway',
              'elementType': 'all',
              'stylers': {
                'color': '#fdfdfd'
              }
            }, {
              'featureType': 'highway',
              'elementType': 'labels',
              'stylers': {
                'visibility': 'off'
              }
            }, {
              'featureType': 'arterial',
              'elementType': 'geometry',
              'stylers': {
                'color': '#fefefe'
              }
            }, {
              'featureType': 'arterial',
              'elementType': 'geometry.fill',
              'stylers': {
                'color': '#fefefe'
              }
            }, {
              'featureType': 'poi',
              'elementType': 'all',
              'stylers': {
                'visibility': 'off'
              }
            }, {
              'featureType': 'green',
              'elementType': 'all',
              'stylers': {
                'visibility': 'off'
              }
            }, {
              'featureType': 'subway',
              'elementType': 'all',
              'stylers': {
                'visibility': 'off'
              }
            }, {
              'featureType': 'manmade',
              'elementType': 'all',
              'stylers': {
                'color': '#d1d1d1'
              }
            }, {
              'featureType': 'local',
              'elementType': 'all',
              'stylers': {
                'color': '#d1d1d1'
              }
            }, {
              'featureType': 'arterial',
              'elementType': 'labels',
              'stylers': {
                'visibility': 'off'
              }
            }, {
              'featureType': 'boundary',
              'elementType': 'all',
              'stylers': {
                'color': '#fefefe'
              }
            }, {
              'featureType': 'building',
              'elementType': 'all',
              'stylers': {
                'color': '#d1d1d1'
              }
            }, {
              'featureType': 'label',
              'elementType': 'labels.text.fill',
              'stylers': {
                'color': '#999999'
              }
            }]
          }
        },
        series: [{
          name: '统计数量',
          type: 'scatter',
          coordinateSystem: 'bmap',
          symbolSize: function(val) {
            return val[2];
          },
          encode: {
            value: 2
          },
          label: {
            formatter: '{b}',
            position: 'right',
            show: false
          },
          emphasis: {
            label: {
              show: true
            }
          }
        },
          {
            name: 'Top 3',
            type: 'effectScatter',
            coordinateSystem: 'bmap',
            symbolSize: function(val) {
              return val[2]/2;
            },
            encode: {
              value: 2
            },
            showEffectOn: 'render',
            rippleEffect: {
              brushType: 'stroke'
            },
            hoverAnimation: true,
            label: {
              formatter: '{b}',
              position: 'right',
              show: true
            },
            itemStyle: {
              shadowBlur: 10,
              shadowColor: '#333'
            },
            zlevel: 1
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      fetch("https://api.juan.k-0n.org/getData?gametype=bf1")
          .then(res =>{
            return res.json()
          })
          .then(data => {
            let dataName = [];
            let geoCoordMap = {};
            for (let record of data.records)
            {
              if(record.pos[0]!=null)
              {
                dataName.push({name:record.name_cn,value:record.count});
                geoCoordMap[record.name_cn]=record.pos;
              }
            }
            const convertData = function (data) {
              let res = [];
              for (let i = 0; i < data.length; i++) {
                let geoCoord = geoCoordMap[data[i].name];
                console.log(geoCoord)
                if (geoCoord) {
                  res.push({
                    name: data[i].name,
                    value: geoCoord.concat(data[i].value)
                  });
                }
              }
              return res;
            };
            myChart.setOption({
              series: [{
                data: convertData(dataName),
                symbolSize: function(val) {
                  return val[2];
                },
              },
                {
                  data: convertData(dataName.sort(function(a, b) {
                    return b.value - a.value;
                  }).slice(0, 3)),
                  symbolSize: function(val) {
                    return val[2];
                  }
                }
              ]
            });
          });

    }
  }
}
</script>
<style>

</style>
