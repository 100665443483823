<template>
  <div>
    <h1
      id="introduction"
      class="uk-h1 tm-heading-fragment uk-text-primary"
    >
      {{ $t('messages.header_title') }}
    </h1>
    <p class="uk-text-default">
      {{ $t('messages.header_detail') }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>

</style>