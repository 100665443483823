export default {
    messages: {
        developers: "",
        header_title: "战地大学生战力排行榜",
        header_detail: "欢迎来到这里！各项数据20分钟更新一次，默认按照数据条数排序，您也可以通过点击表头自定义排序条件！",
        submit_1_title: "校园邮箱提交",
        submit_1_p1: "通过校园邮箱刊载您的数据",
        submit_1_p2: "如果你拥有＠***.edu.*** 结尾的邮箱，请直接使用该邮箱编辑邮件（内容为你的游戏ID、战绩截图、院校名称）发送至root＠k-0n.org，管理员会手动审核通过。",
        //当字符串中含有半角@时报错
        submit_1_p3: "如果方便, 也可以提供有效的在校证明",
        submit_2_title: "问卷提交",
        submit_2_p1: "通过填写问卷来刊载您的数据!",
        submit_2_p2: "请对在校证明图片打码个人信息(如: 照片/姓名/身份证号等)。",
        submit_2_button: "点击这里填写！",
        warning: "警告！",
        hideTip: "请隐藏您的个人信息！",
        confirm: "好的",
        to_bf1: "切换至战地1",
        to_bfv: "切换至战地V",
        table_name: "名称",
        table_number: "条数",
        table_kd: "KD.",
        table_kpm: "KPM.",
        table_average_rank: "平均等级",
        table_average_time: "平均游玩时长",
        table_total_time: "总游玩时长"
    }
}