<template>
  <div>
    <div
      id="warning"
      uk-modal
    >
      <div class="uk-modal-dialog uk-modal-body">
        <h2 class="uk-modal-title uk-text-danger">
          {{ $t('messages.warning') }}
        </h2>
        <p class="uk-text-warning">
          {{ ($t('messages.hideTip')) }}
        </p>
        <p class="uk-text-warning uk-text-bold">
          {{ ($t('messages.hideTip')) }}
        </p>
        <p class="uk-text-warning uk-text-bolder uk-text-large">
          {{ ($t('messages.hideTip')) }}
        </p>
        <a href="https://www.wjx.cn/vj/OgdQ5Zu.aspx"><button
          class="uk-button uk-button-default uk-text-primary"
          type="button"
        >{{ ($t('messages.confirm')) }}</button></a>
      </div>
    </div>
    <div
      class="uk-child-width-1-2@m uk-grid-small uk-grid-match"
      uk-grid
    >
      <div>
        <div class="uk-card uk-card-default uk-card-body uk-animation-fade">
          <h3 class="uk-card-title">
            {{ $t('messages.submit_1_title') }}
          </h3>
          <p>
            {{ $t('messages.submit_1_p1') }}
          </p>
          <p>
            {{ $t('messages.submit_1_p2') }}
          </p>
          <p>
            {{ $t('messages.submit_1_p3') }}
          </p>
        </div>
      </div>
      <div>
        <div class="uk-card uk-card-primary uk-card-body uk-animation-shake">
          <h3 class="uk-card-title">
            {{ $t('messages.submit_2_title') }}
          </h3>
          <p>
            {{ $t('messages.submit_2_p1') }}
          </p>
          <button
            uk-toggle="target: #warning"
            type="button"
            class="uk-button uk-button-default uk-button-large"
          >
            {{ $t('messages.submit_2_button') }}
          </button>
          <p>
            {{ $t('messages.submit_2_p2') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Submit"
}
</script>

<style scoped>

</style>