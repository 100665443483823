export default {
    messages: {
        header_title: "Университетский Рейтинг В «Battlefield»",
        header_detail: "Добро пожаловать сюда!Все данные обновляются каждые 20 минут. Сортировка данных по количеству по умолчанию. Можно также настроить критерии сортировки, нажав на заголовок таблицы！",
        submit_1_title: "Отправьте образовательный адрес электронной почты",
        submit_1_p1: "Запишите свои данные с помощью образовательного почтового ящика",
        submit_1_p2: "Если у вас есть почтовый ящик в конце ＠***.edu.***,используйте этот почтовый ящик для отправки электронной почты непосредственно в root＠k-0n.org(Содержание включает в тебя название игры, скриншот игры и название колледжа)Администратор вручную рассмотрит вашу информацию.",
        //当字符串中含有半角@时报错建议替换为全角字符＠（复制即可）
        submit_1_p3: "Вы также можете предоставить действительное доказательство в школе, если это удобно",
        submit_2_title: "Отправьте анкету",
        submit_2_p1: "Запишите свои данные, заполнив анкету!",
        submit_2_p2: "Не забудьте скрыть свою личную информацию.(Фотография, имя, удостоверение личности и т.д.)。",
        submit_2_button: "Нажмите здесь, чтобы отправить！",
        warning: "Предупреждение!",
        hideTip: "Скрыть вашу личную информацию!",
        confirm: "ДА",
        to_bf1: "Показать «Battlefield 1» Записи",
        to_bfv: "Показать «Battlefield V» Записи",
        table_name: "Имя",
        table_number: "Количество",
        table_kd: "KD.",
        table_kpm: "KPM.",
        table_average_rank: "Средний рейтинг игрока",
        table_average_time: "Среднее затрачено время",
        table_total_time: "Общее затрачено время"
    }
}
