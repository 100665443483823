<template>
  <div>
    <ul uk-tab>
      <li
        :class="{ 'uk-active': $i18n.locale === 'zh'}"
        @click="changeLang('zh')"
      >
        <a href="">简体中文</a>
      </li>
      <li
        :class="{ 'uk-active': $i18n.locale === 'en'}"
        @click="changeLang('en');changeType('list')"
      >
        <a>English</a>
      </li>
      <li
        :class="{ 'uk-active': $i18n.locale === 'ja'}"
        @click="changeLang('ja');changeType('list')"
      >
        <a>日本語</a>
      </li>
      <li
        :class="{ 'uk-active': $i18n.locale === 'de'}"
        @click="changeLang('de');changeType('list')"
      >
        <a>Deutsch</a>
      </li>
      <li
        :class="{ 'uk-active': $i18n.locale === 'ru'}"
        @click="changeLang('ru');changeType('list')"
      >
        <a>Русский</a>
      </li>
    </ul>
    <Header />
    <Submit />
    <hr class="uk-margin-large">
    <ul uk-tab v-if="$i18n.locale === 'zh'">
      <li
          :class="{ 'uk-active': showType === 'list'}"
          @click="changeType('list')"
      >
        <a href="#">显示列表</a>
      </li>
      <li
          :class="{ 'uk-active': showType === 'map'}"
          @click="changeType('map')"
      >
        <a href="#">显示地图</a>
      </li>
    </ul>
    <RecordTable v-if="showType === 'list'"></RecordTable>
    <EchartsMap v-if="showType === 'map'"></EchartsMap>
    <hr class="uk-margin-large">
    <Footer />
  </div>
</template>

<script>

import { getCurrentInstance } from "vue";
import { useI18n } from 'vue-i18n';
import Header from "@/components/Header";
import Submit from "@/components/Submit";
import Footer from "@/components/Footer";
import RecordTable from "@/components/RecordTable";
import EchartsMap from '@/components/EchartsMap'


export default {
  name: 'App',
  components: {
    Header,
    Submit,
    Footer,
    RecordTable,
    EchartsMap
  },
  data(){
    return {
      showType: 'list',
    }
  },
  setup() {
    const { proxy } = getCurrentInstance();
    function changeLang(type) {
      proxy.$i18n.locale = type;
    }
    const { t } = useI18n()
    console.log(t('messages.developers'))
    return { changeLang };
  },
  methods: {
    changeType(type){
      this.showType = type;
    }
  }
}
</script>
