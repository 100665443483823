import zh from './zh'
import en from './en'
import ja from './ja'
import de from './de'
import ru from './ru'
export default {
    zh,
    en,
    ja,
    de,
    ru
}