export default {
    messages: {
        header_title: "Battlefield-Ranking der Hochschulen",
        header_detail: "Willkommen! Die Daten werden pro 20 Minuten aktualisiert. Die Tabelle ist standardmäßig nach Spieleranzahl sortiert. Sie können auch andere Kriterien auswählen z.B. KD oder KPM.",
        submit_1_title: "Per Hochschul-E-Mail senden",
        submit_1_p1: "Sie können Ihre Informationen über Ihre Hochschul-E-Mail übermitteln.",
        submit_1_p2: "Wenn Sie eine E-Mail haben, deren Adresse mit ＠***.edu.*** endet, können Sie Ihre [Origin ID, Records, University/College Name] an root＠k-0n.org senden, unser Administrator wird Ihren Datensatz hinzufügen zu dieser Seite.",
        //当字符串中含有半角@时报错建议替换为全角字符＠（复制即可）
        submit_1_p3: "Wenn es Ihnen passt, können Sie stattdessen auch eine gültige Studentenbescheinigung vorlegen.",
        submit_2_title: "Fragebogeneinreichung",
        submit_2_p1: "Sie können Ihren Daten auch einreichen, indem Sie diesen Fragebogen ausfüllen.",//
        submit_2_p2: "Achtung! Wir benötigen nur die notwendigen Informationen. Bitte ENTFERNEN Sie Ihre persönlichen Daten z.B. Name, Fotos, usw. aus Sicherheitsgründen.",
        submit_2_button: "jetzt Absenden!",
        warning: "Warnung!",
        hideTip: "Hide your personal information",
        confirm: "JA",
        to_bf1: "zu BF1 wechseln",
        to_bfv: "zu BFV wechseln",
        table_name: "Hochschulen",
        //出于语言习惯，这里使用了“高校”一词来替代“名称”
        table_number: "Spieleranzahl",
        table_kd: "KD.",
        table_kpm: "KPM.",
        table_average_rank: "Durchschnittliche Level",
        table_average_time: "Durchschnittliche Spielzeit",
        table_total_time: "Gesamtzeit gespielt"
    }
}