export default {
    messages: {
        header_title: "バトルフィールド - 大学生戦闘力ランキング",
        header_detail: "ようこそ！記録データは20分ごとに更新されます。デフォルトでは、記録表は各自の記録数でソートされています。表のタイトルをクリックしてソートルールを変更することができます！",
        submit_1_title: "大学メールで投稿",
        submit_1_p1: "自分の大学メールでゲーム記録を投稿する",
        submit_1_p2: "もしあなたが＠xxx.edu.cnの末尾のメールアドレスを持っていたら，そのメールアドレスで自分の(Origin Id、ゲーム記録、大学名前)をroot＠k-0n.orgに送信する。管理者はあなたの記録を本ページに追加します。",
        submit_1_p3: "もし可能であれば、有効の在学証明を提供する",
        submit_2_title: "アンケートで投稿",
        submit_2_p1: "アンケートを利用することもできる！注意：内容は中国語だけ。",
        submit_2_p2: "必要な情報だけを提供してください。警告：自分の個人情報を隠すことを忘れないでください(名前、写真などを大事にして)",
        submit_2_button: "ここをクリックする!",
        warning: "警告！",
        hideTip: "個人情報を隠してください！",
        confirm: "分かりました",
        to_bf1: "BF1の記録を示す",
        to_bfv: "BFVの記録を示す",
        table_name: "大学名前",
        table_number: "記録数",
        table_kd: "KD.",
        table_kpm: "KPM.",
        table_average_rank: "平均レベル",
        table_average_time: "平均時間",
        table_total_time: "合計時間"
    }
}